import { initClearNumberIfNotVisibleOnSubmit } from './clearNumberIfNotVisibleOnSubmit';
import { initFormPreventMultipleSubmit } from './formPreventMultipleSubmit';
import { initToggleInputVisibility } from './util';

const init = () => {
  initToggleInputVisibility('customTagOwnerPhoneNumber');
  initClearNumberIfNotVisibleOnSubmit('customTagOwnerPhoneNumber');
  initFormPreventMultipleSubmit('customTagPetPhotoRemove');
};

if (document.readyState !== 'loading') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    init();
  });
}
