// noinspection JSUnusedGlobalSymbols

import { Controller } from '@hotwired/stimulus';
import Modal from 'bootstrap/js/dist/modal';

// eslint-disable-next-line import/no-default-export
export default class extends Controller {
  static targets = ['modal', 'modalTitle', 'modalBody'];

  async open(event) {
    // noinspection JSUnresolvedVariable
    this.modalTitleTarget.innerHTML = event.target.hasAttribute('data-modal-title') ? event.target.dataset.modalTitle : '';

    // noinspection JSUnresolvedVariable
    this.modalBodyTarget.innerHTML = 'Loading...';

    // noinspection JSUnresolvedVariable
    this.modalBodyTarget.className = event.target.hasAttribute('data-modal-body-class') ? `modal-body ${event.target.dataset.modalBodyClass}` : 'modal-body';

    // noinspection JSUnresolvedVariable
    const modal = new Modal(this.modalTarget);
    modal.show();

    const url = event.target.dataset.modalBodyPath;

    fetch(url, {
      headers: new Headers({ 'X-Requested-With': 'XMLHttpRequest' }),
    }).then((response) => {
      if (!response.ok) {
        window.location = url;
      }

      response.text().then((result) => {
        // noinspection JSUnresolvedVariable
        this.modalBodyTarget.innerHTML = result;
      }).catch(() => {
        window.location = url;
      });
    }).catch(() => {
      window.location = url;
    });
  }
}
