export function initFormPreventMultipleSubmit(name) {
  const form = document.querySelector(`[data-form=${CSS.escape(name)}]`);

  if (form === null) {
    return;
  }

  const submitButton = form.querySelector('button[type="submit"]');

  if (submitButton === null) {
    return;
  }

  form.addEventListener('submit', (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    submitButton.disabled = true;
    form.submit();
  });
}
