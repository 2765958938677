// start the Stimulus application
import './bootstrap';

import './bootstrap/vendor';

import './app/customTagConfiguration';
import './app/customCardConfiguration';
import './app/petProfile';

import './app/zendeskWidget';

// Add polyfill because of some "CSS is not defined" errors in Sentry
import 'css.escape';

import * as Sentry from '@sentry/browser';

const htmlElement = document.documentElement;
const { appEnv, appRelease, sentryJsDsn } = htmlElement.dataset;

Sentry.init({
  dsn: appEnv === 'prod' ? sentryJsDsn : '',
  release: `${appRelease}`,
  environment: `web-${appEnv}`,
  sampleRate: 1,
  tracesSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  autoSessionTracking: true,
  attachStacktrace: true,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
});
