import { hide, show } from './util';

const zendeskWidgetKey = '1ebc55d6-c574-4133-a22e-fb91c2a52885';
const localStorageKey = 'zendesk-widget-loaded';

window.zESettings = {
  // See https://developer.zendesk.com/api-reference/widget/settings
  cookies: false,
  analytics: false,
  errorReporting: false,
  webWidget: {
    chat: {
      connectOnPageLoad: false,
    },
  },
};

function loadZendeskWidgetScript(callback) {
  const zdscript = document.createElement('script');
  zdscript.setAttribute('id', 'ze-snippet');
  zdscript.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskWidgetKey}`;
  (document.getElementsByTagName('body')[0]).appendChild(zdscript);

  window.zdonload = setInterval(() => {
    // eslint-disable-next-line no-undef
    if (typeof zE !== 'undefined' && typeof zE.activate !== 'undefined') {
      clearInterval(window.zdonload);
      callback();
    }
  }, 50, null);
}

const loadAndOpenZendeskWidget = (buttonWrapper, button) => {
  localStorage.setItem(localStorageKey, 'true');
  // eslint-disable-next-line no-param-reassign
  button.disabled = true;
  // eslint-disable-next-line no-param-reassign
  button.innerHTML = '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>';
  loadZendeskWidgetScript(() => {
    // eslint-disable-next-line no-undef
    zE(() => {
      // eslint-disable-next-line no-undef
      $zopim(() => {
        // eslint-disable-next-line no-undef
        $zopim.livechat.cookieLaw.comply();
      });

      // eslint-disable-next-line no-undef
      zE('webWidget', 'open');
      hide(buttonWrapper);
    });
  });
};

const init = () => {
  const buttonWrapper = document.getElementById('zendesk-widget-button-wrapper');
  const button = document.getElementById('zendesk-widget-button');

  if (buttonWrapper === null || button === null) {
    return;
  }

  if (localStorage.getItem(localStorageKey)) {
    loadZendeskWidgetScript(() => {});
  } else {
    show(buttonWrapper);
  }

  button.addEventListener('click', () => {
    loadAndOpenZendeskWidget(buttonWrapper, button);
  });

  document.querySelectorAll('[data-zendesk-widget-activate]').forEach((e) => {
    e.addEventListener('click', () => {
      loadAndOpenZendeskWidget(buttonWrapper, button);
    });
  });
};

if (document.readyState !== 'loading') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    init();
  });
}
