import { hide, show } from './util';
import { initCharacterCounter } from './characterCounter';

const toggleBasicExtraType = (element) => {
  const name = 'petExtraType';
  const extraType = document.querySelector(`[data-input=${CSS.escape(name)}]`);

  if (extraType === null) {
    return;
  }

  const extraTypeHelp = document.getElementById(`${extraType.id}_help`);

  if (extraTypeHelp === null) {
    return;
  }

  hide(extraType);
  if (extraType.hasAttribute('required')) {
    extraType.removeAttribute('required');
  }
  hide(extraTypeHelp);
  if (element.value === 'other') {
    show(extraType);
    extraType.setAttribute('required', 'required');
    show(extraTypeHelp);
  }
};

const initExtraTypeOnChange = () => {
  const name = 'petType';
  const typeDropdown = document.querySelector(`[data-input=${CSS.escape(name)}]`);

  if (typeDropdown === null) {
    return;
  }

  toggleBasicExtraType(typeDropdown);
  typeDropdown.onchange = (event) => toggleBasicExtraType(event.target);
};

const init = () => {
  initExtraTypeOnChange();

  initCharacterCounter('petName');
  initCharacterCounter('petBreed');
  initCharacterCounter('petDateOfBirth');
  initCharacterCounter('petPlaceOfResidence');
  initCharacterCounter('petExtraType');

  initCharacterCounter('petChipNumber');

  initCharacterCounter('petTaxId');
  initCharacterCounter('petTaxAuthority');

  initCharacterCounter('petVeterinarianName');

  initCharacterCounter('petProfileText');

  initCharacterCounter('tagPublicId');
};

if (document.readyState !== 'loading') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    init();
  });
}
