export function initClearNumberIfNotVisibleOnSubmit(name) {
  const form = document.querySelector(`[data-form=${CSS.escape(name)}]`);
  const numberInput = document.querySelector(`[data-input=${CSS.escape(name)}]`);
  const radioChoiceNo = document.querySelector(`[data-choice-no=${CSS.escape(name)}]`);

  if (form === null || numberInput === null || radioChoiceNo === null) {
    return;
  }

  form.addEventListener('submit', (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    if (radioChoiceNo.checked) {
      numberInput.value = null;
    }
    form.submit();
  });
}
