import { initFormPreventMultipleSubmit } from './formPreventMultipleSubmit';
import { initToggleInputVisibility } from './util';

const init = () => {
  initToggleInputVisibility('customCardOwnerAddress');
  initToggleInputVisibility('customCardPetChipNumber');
  initToggleInputVisibility('customCardPetTaxId');
  initToggleInputVisibility('customCardPetVeterinarian');
  initFormPreventMultipleSubmit('customCardPetPhotoRemove');
};

if (document.readyState !== 'loading') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    init();
  });
}
