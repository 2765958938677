function countCharacters(input) {
  const count = input.attributes.maxlength.value - input.value.length;
  const characterCounter = document.getElementById(`${input.attributes.id.value}_help`).children[0];
  characterCounter.textContent = `${count}`;
}

export function initCharacterCounter(name) {
  const input = document.querySelector(`[data-input=${CSS.escape(name)}]`);

  if (input === null) {
    return;
  }

  if (document.readyState !== 'loading') {
    countCharacters(input);
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      countCharacters(input);
    });
  }

  input.addEventListener('keyup', () => {
    countCharacters(input);
  });
}
