export const show = (element) => {
  if (element.classList.contains('d-none')) {
    element.classList.remove('d-none');
  }
};

export const hide = (element) => {
  if (!element.classList.contains('d-none')) {
    element.classList.add('d-none');
  }
};

export const initToggleInputVisibility = (name) => {
  const inputWrapper = document.querySelector(`[data-wrapper=${CSS.escape(name)}]`);
  const inputs = document.querySelectorAll(`[data-input-required=${CSS.escape(name)}]`);
  const radioChoiceYes = document.querySelector(`[data-choice-yes=${CSS.escape(name)}]`);
  const radioChoiceNo = document.querySelector(`[data-choice-no=${CSS.escape(name)}]`);

  // eslint-disable-next-line max-len
  if (inputWrapper === null || inputs === null || radioChoiceYes === null || radioChoiceNo === null) {
    return;
  }

  const toggleInputVisibility = () => {
    if (radioChoiceYes.checked) {
      inputs.forEach((e) => {
        e.setAttribute('required', 'required');
      });
      inputWrapper.classList.remove('d-none');
    } else {
      inputs.forEach((e) => {
        if (e.hasAttribute('required')) {
          e.removeAttribute('required');
        }
      });
      inputWrapper.classList.add('d-none');
    }
  };

  radioChoiceYes.addEventListener('change', toggleInputVisibility);
  radioChoiceNo.addEventListener('change', toggleInputVisibility);

  if (document.readyState !== 'loading') {
    toggleInputVisibility();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      toggleInputVisibility();
    });
  }
};
